import { gql } from '@apollo/client';

export const CREATE_REPORT = gql`
  mutation CreateReport($input: CreateReportInput!) {
    createReport(input: $input) {
      reportId
    }
  }
`;

export const GET_PREVIEW_REPORT = gql`
  mutation GetPreviewReport($input: CreateReportDto!) {
    getPreviewReport(input: $input) {
      pdf
    }
  }
`;

export const TEST_GENERATE_PDF = gql`
  mutation TestGeneratePDF {
    testGeneratePDF
  }
`;

export const CONSUME_REPORT = gql`
  mutation consumeReport {
    consumeReport {
      access_token
      refresh_token
    }
  }
`;

export const GENERATE_PDF = gql`
  mutation generatePdf($input: CreateReportInput!) {
    generatePDFReport(input: $input)
  }
`;

export const GET_ALL_CONFIGS_FOR_PROJECT = gql`
  query getAllConfigsForProject($projectId: String!) {
    getAllConfigsForProject(projectId: $projectId) {
      id
      title
      config {
        conclusion
        name
        mail
        phone
        company
        address
        context
        reportName
        description
        dates {
          startDate
          endDate
        }
        projectPlans {
          id
          comment
        }
        sensors {
          sensor {
            id
            name
          }
          pickedMetrics
          comment
          graphBound {
            min
            max
          }
        }
        sensorsComparisonList {
          measurement
          comment
          graphBound {
            min
            max
          }
          sensorsList {
            sensorId
            sensor {
              id
              name
            }
            measureType {
              types
              graphBound {
                min
                max
              }
              offset
            }
            comment
          }
        }
        logos {
          logo1
          logo2
        }
        colors {
          title
          primary
          secondary
        }
      }
    }
  }
`;

export const SAVE_CONFIG_FOR_PROJECT = gql`
  mutation saveConfigForProject(
    $projectId: String!
    $title: String!
    $config: CreateReportInput!
  ) {
    saveConfigForProject(
      projectId: $projectId
      title: $title
      config: $config
    ) {
      id
    }
  }
`;
