import { SensorFamilyEnum } from '../enums/feelbat.enum';
import { Project, ProjectWithCounts, Sensor } from '../types/feelbat';

const isValidSensorFamily = (
  family: string
): family is keyof typeof SensorFamilyEnum => {
  return Object.keys(SensorFamilyEnum).includes(family);
};

const shouldCountSensor = (sensor: Sensor): boolean => {
  if (isValidSensorFamily(sensor.family)) {
    const sensorFamily = SensorFamilyEnum[sensor.family];

    return (
      (sensorFamily as SensorFamilyEnum) === SensorFamilyEnum.JC1 ||
      (sensorFamily as SensorFamilyEnum) === SensorFamilyEnum.JC2 ||
      ((sensorFamily as SensorFamilyEnum) !== SensorFamilyEnum.JC1 &&
        (sensorFamily as SensorFamilyEnum) !== SensorFamilyEnum.JC2 &&
        sensor.active)
    );
  }
  return false;
};

export const getTModuleAndSensorsCount = (
  data: Project[]
): ProjectWithCounts[] => {
  return data.map((project) => {
    let tmoduleCount = 0;
    let activeSensorsCount = 0;

    if (project.tmodules) {
      tmoduleCount = project.tmodules.length;
      activeSensorsCount = project.tmodules.reduce(
        (acc, tmodule) =>
          acc +
          (tmodule.sensors
            ? tmodule.sensors.filter(shouldCountSensor).length
            : 0),
        0
      );
    }

    const projectWithCounts: ProjectWithCounts = {
      ...project,
      tmoduleCount,
      activeSensorsCount,
    };

    return projectWithCounts;
  });
};

export const getTModuleAndSensorsCountByProject = (project: Project) => {
  const tmoduleCount = project?.tmodules?.length;

  const activeSensorsCount = project?.tmodules?.reduce((count, tmodule) => {
    return count + tmodule.sensors.filter(shouldCountSensor).length;
  }, 0);

  return { tmoduleCount, activeSensorsCount };
};

export const camelToSnakeCaseObj = (
  obj: Record<string, any>
): Record<string, any> => {
  return Object.keys(obj).reduce((result: Record<string, any>, key) => {
    const newKey = key.replace(
      /[A-Z]/g,
      (letter) => `_${letter.toLowerCase()}`
    );
    result[newKey] = obj[key];
    return result;
  }, {});
};

/**
 * Sorts an array of objects by a specified field.
 * @param array The array to sort.
 * @param field The field by which to sort the objects.
 * @param direction The direction to sort the objects. Default to ascending direction.
 * @returns A new array sorted by the specified field.
 */
export const sortItemsBy = <T>(
  array: T[] | undefined,
  field: keyof T,
  direction: 'ASC' | 'DESC' = 'ASC' // Default to ascending order
): T[] => {
  if (array === undefined) return [];

  return Array.from(array).sort((a: T, b: T) => {
    const [nameA, numA] = String(a[field]).split(/[_-]/);
    const [nameB, numB] = String(b[field]).split(/[_-]/);

    if (nameA === nameB) {
      // Sort numerically based on the direction
      const numComparison = parseInt(numA) - parseInt(numB);
      return direction === 'ASC' ? numComparison : -numComparison;
    }

    // Sort alphabetically based on the direction
    const nameComparison = nameA.localeCompare(nameB);
    return direction === 'ASC' ? nameComparison : -nameComparison;
  });
};

/**
 * Filters an array of objects by a specific value at a given path.
 *
 * @template T - The type of objects in the array.
 * @param {T[]} array - The array of objects to filter.
 * @param {string} path - The path to the nested property in each object to compare.
 *                        The path is a dot-separated string representing the nested keys (e.g., "user.name").
 * @param {any} value - The value to match against the property at the specified path.
 * @returns {T[]} - A new array containing only the objects that match the specified value.
 */
export const filterBy = <T>(array: T[], path: string, value: any): T[] => {
  return array.filter((item) => getNestedValue(item, path) === value);
};

/**
 * Constrains a number within a specified range.
 * @param value The value to be constrained.
 * @param min The minimum value of the range.
 * @param max The maximum value of the range.
 * @returns The constrained value within the specified range.
 */
export const rangeNumber = (
  value: number,
  min: number,
  max: number
): number => {
  if (min < value && value < max) {
    return value;
  }
  if (min > value) {
    return min;
  }
  return max;
};

export const DECIMALS_LENGTH = 2;

export const formatNumber = (
  number: Number | undefined
): string | undefined => {
  return number?.toFixed(DECIMALS_LENGTH);
};

/**
 * Retrieves the value at a nested path within an object.
 *
 * @param {any} obj - The object from which to retrieve the nested value.
 * @param {string} path - A dot-separated string representing the path to the nested value (e.g., "user.name").
 * @returns {any} - The value at the specified path, or `undefined` if any part of the path does not exist.
 */
const getNestedValue = (obj: any, path: string): any => {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};
